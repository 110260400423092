import React from "react";
import { LikeButton } from "../../components/LikeButton";
import usePageTracking from "../../useTracking";

import img1 from "./images/cfd-l.jpg";
import img2 from "./images/cfd-s1.jpg";
import img3 from "./images/cfd-s2.jpg";

export default function Page() {
  usePageTracking();
  return (
    <>
      <div className="row mb-0">
        <div className="col mx-0 px-0">
          <a href={img1} target="_blank" rel="noopener noreferrer">
            <img src={img1} className="img-fluid" alt="枯山水1" />
          </a>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 mx-0 px-0">
          <a href={img2} target="_blank" rel="noopener noreferrer">
            <img src={img2} className="img-fluid" alt="枯山水2" />
          </a>
        </div>
        <div className="col-6 mx-0 px-0">
          <a href={img3} target="_blank" rel="noopener noreferrer">
            <img src={img3} className="img-fluid" alt="枯山水2" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="title mb-0">C.F.D.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-0">2024</p>
          <p className="mb-0">インク・紙</p>
        </div>
        <div className="col-auto text-end align-self-end">
          <LikeButton id="2024-11_cfd" />
        </div>
      </div>
      <div className="row">
        <div className="col"> </div>
      </div>
    </>
  );
}
